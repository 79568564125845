import React from 'react'
import {Link} from 'gatsby'

// Components
import Layout from '../layouts'
import Nav from '../components/Nav'
import Footer from '../components/Footer'

function GdprPage() {
  return (
    <Layout pageName="GDPR">
      <Nav/>
      <div className="maxwidth-xsmall">
        <div className="content legal">
          <h1>GDPR Commitment</h1>
          <div className="last-revision">Last revision: March 11, 2019</div>
          <ol>
            <li>
              <h3>Protecting Your Data</h3>
              <p>
                The <a href="http://www.eugdpr.org/">GDPR</a> is the most
                comprehensive EU data privacy law in decades, and went into effect
                on May 25, 2018. Besides strengthening and standardizing user data
                privacy across the EU, it requires new or additional obligations
                on all organizations that handle EU citizens’ personal data,
                regardless of where the organizations themselves are located. On
                this page, we’ll explain our methods and plans to achieve
                GDPR-compliance.
              </p>
            </li>
            <li>
              <h3>Preparing for the GDPR</h3>
              <p>
                The GDPR's updated requirements are significant and our team is
                working diligently ensure Bizy is in compliance. Measures to
                achieve this include:
                <ul>
                  <li>
                    <p>Continuing to invest in security infrastructure</p>
                  </li>
                  <li>
                    <p>
                      Ensuring we can support international data transfers by
                      maintaining Privacy Shield self-certifications
                    </p>
                  </li>
                  <li>
                    <p>
                      Changing our policies and product offerings to include tools
                      for data management
                    </p>
                  </li>
                </ul>
                We will also continue to monitor the guidance around GDPR
                compliance from privacy-related regulatory bodies and adjust our
                plan accordingly.
              </p>
            </li>
            <li>
              <h3>Privacy Shield</h3>
              <p>
                Bizy is <a href="/privacy-shield">Privacy Shield certified</a>.
                Privacy Shield is a voluntary program for US organizations to show
                that they have adequate data protections in place to meet EU
                requirements regarding the transfer of personal data outside of
                the EU. Bizy will work hard to maintain its Privacy Shield
                commitments and looks forward to the success of the program.
              </p>
            </li>
            <li>
              <h3>Commitments as a Data Controller</h3>
              <p>
                <a href="https://gdpr-info.eu/art-24-gdpr/">Data controllers</a> are companies that supply goods or
                services to EU residents, or
                that track or monitor EU residents and decide why and how data is
                collected and processed.{' '}
                <a href="https://gdpr-info.eu/art-28-gdpr/">
                  Data processors
                </a>{' '}
                are vendors or businesses that process data on behalf of data
                controllers. As a Data Controller, we are committed to both
                ensuring our practices are sound within the scope of the GDPR, as
                well as ensuring we only work with compliant data processors. In
                support of this, the following are initiatives we have undertaken:
              </p>
              <ol>
                <li>
                  <h6>Information Audit:</h6>
                  <p>
                    As part of the GDPR, we have completed a thorough audit of
                    all data collection, data flows, and data processing within
                    Bizy and between us and our cloud vendors.
                  </p>
                </li>
                <li>
                  <h6>Information Asset Register:</h6>
                  <p>
                    From our information audit, we have created an information
                    asset register. This allows to ensure we are properly
                    tracking, securing, and when applicable, removing user
                    information across our internal systems.
                  </p>
                </li>
                <li>
                  <h6>Vendor Compliance:</h6>
                  <p>
                    We are following up with all of our vendors to ensure they
                    are on track to achieve and maintain GDPR compliance.
                  </p>
                </li>
                <li>
                  <h6>Support for Deletion Requests:</h6>
                  <p>
                    Bizy has always allowed for users to request deletion of
                    their account and application data. Going forward, we will
                    implement additional messaging to make this feature more
                    widely known.
                  </p>
                </li>
                <li>
                  <h6>Breach Notification Policy:</h6>
                  <p>
                    In line with our current policies, Bizy will promptly inform
                    users of any incidents involving user data.
                  </p>
                </li>
                <li>
                  <h6>Lawful Bases Identification:</h6>
                  <p>
                    The GDPR allows for a{' '}
                    <a
                      href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/lawful-basis-for-processing/#table">
                      number of lawful bases
                    </a>{' '}
                    for processing data. Bizy is committed to documenting our
                    bases for data processing and will work to inform users in
                    the appropriate manner.
                  </p>
                </li>
                <li>
                  <h6>Product Adjustments:</h6>
                  <p>
                    As part of compliance, Bizy will continue to modify the
                    product to ensure we are meeting both regulators' and users'
                    expectations under this law.
                  </p>
                </li>
                <li>
                  <p>
                    The GPDR is a complex law, with many best practices yet to be
                    agreed upon. Bizy is committed to following developments in this
                    area and implementing best practices as they emerge in a timely
                    manner. As a company, we believe the new legal requirements will
                    raise the bar for honoring end users’ rights.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h3>Resources</h3>
              <ul className="resources">
                <li>
                  <p>
                    <a href="https://www.privacyshield.gov/Program-Overview">
                      E.U.-U.S. and Swiss-U.S. Privacy Shield
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    <a href="https://gdpr-info.eu/">Full text of the GDPR</a>
                  </p>
                </li>
                <li>
                  <p>
                    <a href="/privacy">Bizy's Privacy Policy</a>
                  </p>
                </li>
                <li>
                  <p>
                    <a href="/privacy-shield">Bizy's Privacy Shield Notice</a>
                  </p>
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
      <Footer/>
    </Layout>
  )
}

export default GdprPage
